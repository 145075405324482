///Example

#footer {
    background: #f2f5f8;
    padding: 0 0 30px 0;
    font-size: 14px;
  }
  
  #footer .copyright {
    text-align: center;
    padding-top: 30px;
  }
  