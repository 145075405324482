
/* Intro Section
--------------------------------*/

// .wrappercontent{
//     padding-top: 124px;
// }
#intro {
    width: 100%;
    height: 60vh;
    position: relative;
    background: url("../../../assets/img/intro.jpg") no-repeat;
    background-size: cover;
  }
  
  #intro .intro-content {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  
  #intro .intro-content h2 {
    color: #0c2e8a;
    margin-bottom: 30px;
    font-size: 64px;
    font-weight: 700;
  }
  
  #intro .intro-content h2 span {
    color: #50d8af;
    text-decoration: underline;
  }
  
//   #intro .intro-content .btn-get-started,
//   #intro .intro-content .btn-projects {
//     font-family: "Raleway", sans-serif;
//     font-size: 15px;
//     font-weight: bold;
//     letter-spacing: 1px;
//     display: inline-block;
//     padding: 10px 32px;
//     border-radius: 2px;
//     transition: 0.5s;
//     margin: 10px;
//     color: #fff;
//   }
  
//   #intro .intro-content .btn-get-started {
//     background: #0c2e8a;
//     border: 2px solid #0c2e8a;
//   }
  
//   #intro .intro-content .btn-get-started:hover {
//     background: none;
//     color: #0c2e8a;
//   }
  
//   #intro .intro-content .btn-projects {
//     background: #50d8af;
//     border: 2px solid #50d8af;
//   }
  
//   #intro .intro-content .btn-projects:hover {
//     background: none;
//     color: #50d8af;
//   }
  
/* About Section
--------------------------------*/

#about {
    padding: 60px 0 30px 0;
  }
  
  #about .about-img {
    overflow: hidden;
  }
  
  #about .about-img img {
    margin-left: -15px;
    max-width: 100%;
  }
  
  #about .content h2 {
    color: #0c2e8a;
    font-weight: 700;
    font-size: 36px;
    font-family: "Raleway", sans-serif;
  }
  
  #about .content h3 {
    color: #555;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    font-style: italic;
  }
  
  #about .content p {
    line-height: 26px;
  }
  
  #about .content p:last-child {
    margin-bottom: 0;
  }
  
  #about .content i {
    font-size: 20px;
    padding-right: 4px;
    color: #50d8af;
  }
  
  #about .content ul {
    list-style: none;
    padding: 0;
  }
  
  #about .content ul li {
    padding-bottom: 10px;
  }


  /* Services Section
--------------------------------*/

#services {
  padding: 30px 0 0 0;
}

#services .box {
  padding: 40px;
  margin-bottom: 40px;
  box-shadow: 10px 10px 15px rgba(73, 78, 92, 0.1);
  background: #fff;
  transition: 0.4s;
}

#services .box:hover {
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
}

#services .box .icon {
  float: left;
}

#services .box .icon i {
  color: #444;
  font-size: 64px;
  transition: 0.5s;
  line-height: 0;
  margin-top: 34px;
}

#services .box .icon i:before {
  background: #0c2e8a;
  background: linear-gradient(45deg, #50d8af 0%, #a3ebd5 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#services .box h4 {
  margin-left: 100px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 22px;
}

#services .box h4 a {
  color: #444;
}

#services .box p {
  font-size: 14px;
  margin-left: 100px;
  margin-bottom: 0;
  line-height: 24px;
}

/* Contact Section
--------------------------------*/

#contact {
    padding: 30px 0;
  }
  
  #contact .contact-info {
    margin-bottom: 20px;
    text-align: center;
  }
  
  #contact .contact-info i {
    font-size: 48px;
    display: inline-block;
    margin-bottom: 10px;
    color: #50d8af;
  }
  
  #contact .contact-info address,
  #contact .contact-info p {
    margin-bottom: 0;
    color: #000;
  }
  
  #contact .contact-info h3 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: bold;
    text-transform: uppercase;
    color: #999;
  }
  
  #contact .contact-info a {
    color: #000;
  }
  
  #contact .contact-info a:hover {
    color: #50d8af;
  }
  
  #contact .contact-address,
  #contact .contact-phone,
  #contact .contact-email {
    margin-bottom: 20px;
  }
   
  #contact .form #sendmessage {
    color: #50d8af;
    border: 1px solid #50d8af;
    display: none;
    text-align: center;
    padding: 15px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  
  #contact .form #errormessage {
    color: red;
    border: 1px solid red;
    text-align: center;
    display: none;
    padding: 15px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  
  #contact .form #sendmessage.show,
  #contact .form #errormessage.show,
  #contact .form .show {
    display: block;
  }
  
  #contact .form .validation {
    color: red;
     margin: 0 0 20px;
    font-weight: 400;
    font-size: 13px;
  }

  #contact .form .validation {
    color: red;
     margin: 0 0 20px;
     display: none;
    font-weight: 400;
    font-size: 13px;
  }

  #contact .form .validation.showerror {
    display: block;
  }


  
  #contact .form input,
  #contact .form textarea {
    padding: 10px 14px;
    border-radius: 0;
    box-shadow: none;
    font-size: 15px;
  }
  
  #contact .form button {
    background: #50d8af;
    border: 0;
    border-radius: 3px;
    padding: 10px 30px;
    color: #fff;
    transition: 0.4s;
    cursor: pointer;
  }
  
  #contact .form button:hover {
    background: #2dc899;
  }