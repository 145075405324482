// Import Bootstrap and its default variables

html,body{
    overflow-x: hidden;
  }
  

$body-bg: #f5f8fa;
$body-color: #86939e;
$primary : #50d8af;
$link-color:                              #555  !default;
$link-decoration:                         none !default;
$link-hover-color:                        $primary !default;
$link-hover-decoration:                   none !default;

@import 'bootstrap/scss/bootstrap.scss';